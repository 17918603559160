import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import {getFileMetadataById } from "./APImodules.js";


// ICONS
import download from "../img/download-arrow.png";
import zip from "../img/filetypes/zip.png";

function ZipContainer(props) {
  const FILE_ID = props.children.id;

  // STATE
  const [isLoading, setIsLoading] = useState(true);
  const [zipMetadata, setZipMetadata] = useState(true);

  // API CALLS
  useEffect(() => {
    setIsLoading(true);
    GetDatasets();
  }, []);

  async function GetDatasets() {
    const result = await getFileMetadataById(FILE_ID);
    setZipMetadata(result);
    setIsLoading(false);
  }

  if (isLoading) {
    return <h1>LOADING...</h1>;
  }

  return (
    <div
      className="text-white py-2 px-4 rounded mt-4 flex justify-between items-center"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.2)), url("https://c4.wallpaperflare.com/wallpaper/356/292/33/music-rock-and-roll-vinyl-album-covers-wallpaper-preview.jpg")`,
      }}
    >
      <div className="items-center">
        <div className="relative">
          <img src={zip} className="absolute h-10 w-10 -left-10 top-2"></img>
        </div>
        <div className="flex">
          <div className="p-2 flex-initial w-40 break-words">
            <strong>{props.children.filename}</strong>
            <br />
          </div>
          <div className="flex-grow"></div>{" "}
          {/* This will push the download button to the far right */}
          <div className="py-2 pr-2">
            <a
              href={`https://clowder.eessi.science/api/files/${props.children.id}/`}
            >
              <img
                src={download}
                className="h-8 cursor-pointer"
                alt="Download Icon"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ZipContainer;