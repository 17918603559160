import React from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import {getFileMetadataById } from "./APImodules.js";

//ICONS
import link from "../img/filetypes/link.png";
import github from "../img/filetypes/github.png";
import gitlab from "../img/filetypes/gitlab.png";
import download from "../img/download-arrow.png";

function UrlContainer(props) {
  const FILE_ID = props.children.id;

  //STATE
  const [isLoading, setIsLoading] = useState(true);
  const [urlMetadata, setUrlMetadata] = useState(true);

  //API CALLS
  useEffect(() => {
    setIsLoading(true);
    GetDatasets();
  }, []);

  async function GetDatasets() {
    const result = await getFileMetadataById(FILE_ID);
    setUrlMetadata(result);
    setIsLoading(false);
  }

  if (isLoading) {
    return <h1>LOADING...</h1>;
  }

  return (
    <div
      className="bg-blue-300 text-white py-2 px-4 rounded mt-4 cursor-pointer"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.2)), url("https://clowder.eessi.science/api/previews/${urlMetadata[0].content.clowder_preview_image}")`,
      }}
    >
      <a
        href={urlMetadata[0].content.URL}
        target="_blank"
        className="no-underline text-white"
      >
        {urlMetadata[0].content.clowder_git_repo ? (
          <div>
            <div className="pr-2 relative">
              {urlMetadata[0].content.clowder_git_type == "gitlab" ? (
                <div>
                  <img src={gitlab} className="absolute h-10 w-10 -left-10 top-2"></img>
                </div>
              ) : (
                <div>
                  <img src={github}className="absolute h-10 w-10 -left-10 top-2"></img>
                </div>
              )}
            </div>
            <div className="p-2 flex-initial break-words">
              <strong>{urlMetadata[0].content.clowder_page_title}</strong>
            </div>
          </div>
        ) : (
          <div>
            <div className="pr-2 relative">
              <img src={link} className="absolute h-10 w-10 -left-10 top-2"></img>
            </div>
            <div className="p-2 w-auto break-words">
              <p>{urlMetadata[0].content.title}</p>
            </div>
          </div>
        )}
      </a>
    </div>
  );
}

export default UrlContainer;
