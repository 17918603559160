import React, { useState, useEffect, useMemo, useRef } from "react";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import css from "../Pages.module.css";
import LhumosSpaces from "../spaces.json";
import { getDatasetsByCollectionId, getCollectionById, getChildCollectionsByCollectionId } from "./APImodules.js";
import LoadingCircle from "./LoadingCircle";
import DatasetLinkContainer from "./DatasetLinkContainer";
import Slider from "react-slick";
import video from "../img/video.png";
import Arrow from "../img/arrow-down.png";
import ExtractJsonFromDescription from "./ExtractJsonFromDescription.js";

function CollectionContainer({ children, spaceIndex, isExpanded, onToggleExpand, isTopLevel }) {
  const [isLoading, setIsLoading] = useState(true);
  const [datasets, setDatasets] = useState([]);
  const [isPlaylist, setIsPlaylist] = useState(false);
  const [collections, setCollections] = useState([]);
  const [filteredDescription, setFilteredDescription] = useState("");

  const COLLECTION_ID = children.id;

  const sliderRef = useRef(null);

  useEffect(() => {
    setIsLoading(true);
    fetchCollectionData();
  }, [COLLECTION_ID]);

  const fetchCollectionData = async () => {
    try {
      const datasets = await fetchDatasets();
      const collections = await fetchCollections();
      setDatasets(datasets);
      setCollections(collections);
      setIsPlaylist(collections.length > 0);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching collection data:", error);
      setIsLoading(false);
    }
  };

  const fetchDatasets = async () => {
    const result = await getDatasetsByCollectionId(COLLECTION_ID, "test");
    if (!result) return [];
    const filteredResult = result.filter(dataset => !dataset.name.includes("[files]"));
    const collectionData = await getCollectionById(COLLECTION_ID);
    if (!collectionData) return filteredResult;

    const { json: datasetsOrder, remainingDescription } = ExtractJsonFromDescription(collectionData.description);
    setFilteredDescription(remainingDescription);

    return datasetsOrder || filteredResult;
  };

  const fetchCollections = async () => {
    const result = await getChildCollectionsByCollectionId(COLLECTION_ID, "test");
    return result || [];
  };

  const [localExpanded, setLocalExpanded] = useState(false);

  const handleLocalToggleExpand = (e) => {
    e.stopPropagation();
    setLocalExpanded(!localExpanded);
  };

  const handleContainerClick = () => {
    if (!isExpanded && !localExpanded) {
      if (isTopLevel) {
        onToggleExpand();
      } else {
        setLocalExpanded(true);
      }
    }
  };

  const sliderSettings = useMemo(() => ({
    infinite: false,
    lazyLoad: true,
    speed: 300,
    dots: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    centerMode: false,
    centerPadding: "50px",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }), []);

  if (isLoading) {
    return <LoadingCircle />;
  }

  if (!isPlaylist) {
    return (
      <div className="m-4 w-full lg:w-5/12 md:w-2/3 sm:w-full grow rounded-2xl overflow-hidden" style={{ backgroundColor: LhumosSpaces[spaceIndex].color2 }}>
        <div className={`${css.containerClass} ${!filteredDescription ? "h-10" : "h-50"}`} style={filteredDescription ? { background: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.4)), url("https://clowder.eessi.science/fileThumbnail/${children.thumbnail}/blob")`, backgroundPosition: "center", backgroundSize: "cover" } : {}}>
          <div className="flex px-4 pt-4 justify-start">
            <h3 className="text-white text-xl font-semibold mr-8">{children.collectionname || children.name}</h3>
            <div className="flex ml-auto items-center justify-end">
              {datasets.length}
              <img className="w-8 h-8 ml-4" src={video} alt="video" />
            </div>
          </div>
          <div className="bg-opacity-50 backdrop-blur-2xl rounded-lg p-2 m-2">
            <p className="text-white text-xs">{filteredDescription.substring(0, 500)}</p>
          </div>
        </div>
        <div className="flex-grow">
          <div ref={sliderRef} className="rounded-lg p-6 overflow-hidden w-full">
            <Slider {...sliderSettings}>
              {datasets.map((dataset, idx) => (
                <DatasetLinkContainer key={dataset.id} spaceIndex={spaceIndex} dataset={dataset} index={idx} collectionId={COLLECTION_ID} />
              ))}
            </Slider>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className="bg-gradient-to-br from-black via-black to-transparent mx-4 mt-4 rounded-2xl w-full overflow-hidden relative"
      onClick={handleContainerClick}
      style={{
        background: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.4)), url("https://clowder.eessi.science/fileThumbnail/${children.thumbnail}/blob")`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        cursor: !isExpanded && !localExpanded ? "pointer" : "default",
      }}
    >
      <img
        src={Arrow}
        className={`h-8 m-6 absolute right-0 cursor-pointer transition duration-300 ${isTopLevel ? (isExpanded ? "rotate-180" : "") : (localExpanded ? "rotate-180" : "")}`}
        onClick={(e) => {
          e.stopPropagation();
          if (isTopLevel) {
            onToggleExpand();
          } else {
            handleLocalToggleExpand(e);
          }
        }}
        alt="Expand/Collapse Arrow"
      />
      <h1 className="text-white text-2xl font-semibold px-4 pt-4">{children.collectionname || children.name}</h1>
      <div className="my-4 bg-opacity-50 rounded-lg backdrop-blur-2xl">
        <h6 className="text-gray-300 mx-4">{children.description.substring(0, 800)}</h6>
      </div>
      {(isTopLevel ? isExpanded : localExpanded) && (
        <div className="flex flex-wrap overflow-hidden">
          {collections.map((collection, idx) => (
            <CollectionContainer key={collection.id} spaceIndex={spaceIndex} index={idx} isTopLevel={false}>
              {collection}
            </CollectionContainer>
          ))}
        </div>
      )}
    </div>
  );
}

export default CollectionContainer;