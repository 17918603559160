import { useRef, useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import Slider from "react-slick";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import Breadcrumbs from "./components/Breadcrumbs";
import SharedFiles from "./components/SharedFiles";
import VideoPlayer from "react-video-js-player";
import PdfContainer from "./components/PdfContainer";
import UrlContainer from "./components/UrlContainer";
import ZipContainer from "./components/ZipContainer";
import LoadingScreen from "./components/LoadingScreen";
import LhumosSpaces from "./spaces.json";
import "./Player.css";
import {
  ApiGet,
  getFilesByDatasetId,
  getDatasetMetadataByFileId,
  getDatasetsByCollectionId,
  getCollectionById,
} from "./components/APImodules.js";
import ExtractJsonFromDescription from "./components/ExtractJsonFromDescription.js";

function Player() {
  const [isLoading, setIsLoading] = useState(true);
  const [slides, setSlides] = useState([]);
  const [playlist, setPlaylist] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [descriptionVisible, setDescriptionVisible] = useState(!isMobile);
  const [slideIndex, setSlideIndex] = useState(0);
  const [files, setFiles] = useState([]);
  const [dataset, setDataset] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(0.5);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const {
    spaceId: SPACE_INDEX,
    videoNumber: VIDEO_NUMBER,
    collectionId: COLLECTION_ID,
    datasetId: DATASET_ID,
  } = useParams();

  const playerRef = useRef(null);
  const slider = useRef(null);

  useEffect(() => {
    setIsLoading(true);
    GetAllData();
    window.scrollTo(0, 0);
  }, [SPACE_INDEX, COLLECTION_ID, DATASET_ID, VIDEO_NUMBER]);

  async function GetAllData() {
    const filesData = await getFilesByDatasetId(DATASET_ID);
    setFiles(filesData);
    const videoFile = filesData.find(
      (file) => file.contentType === "video/mp4"
    );
    const datasetData = await getDatasetMetadataByFileId(videoFile.id);
    setDataset(datasetData);

    setSlides(datasetData[0].content.listslides);

    const playlistResult = await getDatasetsByCollectionId(
      COLLECTION_ID,
      "test"
    );
    const filteredPlaylistResult = playlistResult.filter(
      (dataset) => !dataset.name.includes("[files]")
    );

    const collectionData = await getCollectionById(COLLECTION_ID);
    if (collectionData) {
      const { json: playlistOrder } = ExtractJsonFromDescription(
        collectionData.description
      );
      if (playlistOrder && Array.isArray(playlistOrder)) {
        const orderedAndFilteredPlaylist = playlistOrder.filter((dataset) =>
          filteredPlaylistResult.some(
            (filteredDataset) => filteredDataset.id === dataset.id
          )
        );
        setPlaylist(orderedAndFilteredPlaylist);
      } else {
        setPlaylist(filteredPlaylistResult);
      }
    } else {
      setPlaylist(filteredPlaylistResult);
    }

    setIsLoading(false);
  }

  useEffect(() => {
    if (!isLoading && playlist.length > 0) {
      const jsonString = playlist[VIDEO_NUMBER].description;
      const extractedJSON = extractJSONFromString(jsonString);
      if (extractedJSON !== null) {
        const filteredSlides = extractedJSON.filter(
          (slide) => slide.isRemoved === false
        );
        setSlides(filteredSlides);
      }
    }
  }, [isLoading, playlist, VIDEO_NUMBER]);

  useEffect(() => {
    if (slides.length > 0) {
      const intervalId = setInterval(() => {
        if (playerRef.current) {
          const currentTime = playerRef.current.player.currentTime();
          const closestSlideIndex =
            calculateClosestPassedSlideIndex(currentTime);
          setSlideIndex(closestSlideIndex);
        }
      }, 1000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [slides]);

  function extractJSONFromString(string) {
    const regex = /\[slides\](.*?)\[endslides\]/s;
    const match = string.match(regex);
    if (match && match[1]) {
      const json = match[1].trim();
      return JSON.parse(json);
    }
    return null;
  }

  function calculateClosestPassedSlideIndex(currentTime) {
    let closestSlideIndex = -1;
    let minTimeDifference = Number.MAX_SAFE_INTEGER;

    for (let i = 0; i < slides.length; i++) {
      if (slides[i][3] !== undefined && currentTime >= slides[i][3]) {
        const timeDifference = currentTime - slides[i][3];
        if (timeDifference < minTimeDifference) {
          closestSlideIndex = i;
          minTimeDifference = timeDifference;
        }
      }
    }

    return closestSlideIndex;
  }

  function jumpToTime(time) {
    if (playerRef.current) {
      playerRef.current.player.currentTime(time);
    }
  }

  function skipToCurrentSlide() {
    slider.current.slickGoTo(slideIndex);
  }

  const sliderSettings = {
    infinite: false,
    lazyLoad: false,
    speed: 300,
    slidesToShow: 4,
    dots: false,
    centerMode: false,
    beforeChange: (current, next) => setSlideIndex(next),
  };

  const playlistSettings = {
    infinite: false,
    lazyLoad: true,
    speed: 300,
    vertical: true,
    focusOnSelect: true,
    slidesToShow: playlist.length > 4 ? 4 : playlist.length - 1,
    centerMode: false,
    centerPadding: 100,
  };

  const videoSource =
    dataset.length > 0
      ? `https://clowder.eessi.science/api/previews/${dataset[0].content.previews.mp4}#.mp4`
      : "";

  if (isLoading) {
    return <LoadingScreen />;
  }

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  return (
    <div
      className="m-auto"
      style={{
        backgroundImage: `linear-gradient(to bottom, ${LhumosSpaces[SPACE_INDEX].color1}, #000000)`,
      }}
    >
      <Breadcrumbs videoName={playlist[VIDEO_NUMBER].name} />
      <div className="lg:px-8">
        <div className="md:grid lg:grid grid-cols-12 gap-8">
          <div className="lg:col-span-9 md:col-span-12 sm:col-span-12">
            <div className="lg:px-4">
              <div
                className="border-none rounded-3xl p-4"
                style={{
                  backgroundImage: `linear-gradient(to bottom, ${LhumosSpaces[SPACE_INDEX].color2}, rgba(0, 0, 0, 0))`,
                }}
              >
                <VideoPlayer
                  options={{
                    autoplay: true,
                    controls: true,
                    responsive: true,
                    fluid: true,
                  }}
                  id="lhumos-player"
                  src={videoSource}
                  type={"video/mp4"}
                  className="rounded-2xl w-full h-[44rem] l mb-4"
                  ref={playerRef}
                  onEnded={() => setIsPlaying(false)}
                />

                <div className="flex items-center justify-between mt-4">
                  <div className="flex items-center"></div>
                  <button
                    onClick={skipToCurrentSlide}
                    className="mt-2 p-2 text-white rounded-lg"
                    style={{
                      backgroundColor: LhumosSpaces[SPACE_INDEX].color1,
                    }}
                  >
                    Skip to Current Slide
                  </button>
                </div>

                <div className="mt-8">
                  <Slider
                    {...sliderSettings}
                    ref={slider}
                    currentSlide={slideIndex}
                  >
                    {slides.map((img, idx) => (
                      <div
                        key={idx}
                        onClick={() => jumpToTime(slides[idx][3])}
                        className={`w-8 rounded-xl cursor-pointer transition delay-100 ease-in-out hover:-translate-y-1 hover:scale-95 relative ${
                          idx === slideIndex
                            ? "border-4 border-red-600 outline-none"
                            : ""
                        }`}
                        style={{
                          borderColor: LhumosSpaces[SPACE_INDEX].accent,
                        }}
                      >
                        <div
                          className="text-white rounded-full top-2 left-2 w-6 h-6 flex justify-center items-center p-3 absolute"
                          style={{
                            backgroundColor: LhumosSpaces[SPACE_INDEX].accent,
                          }}
                        >
                          <h6>{idx + 1}</h6>
                        </div>
                        <img
                          src={`https://clowder.eessi.science/api/previews/${img[2]}`}
                          alt={img[2]}
                          className="rounded-xl"
                        />
                      </div>
                    ))}
                  </Slider>
                </div>
                <div className="bg-neutral-200 rounded-2xl p-4 mt-8">
                  <h4 className="text-xl font-semibold mb-2">Description</h4>
                  {descriptionVisible
                    ? playlist[VIDEO_NUMBER].description.replace(
                        /\[slides\][\s\S]*?\[endslides\]/g,
                        ""
                      )
                    : playlist[VIDEO_NUMBER].description
                        .replace(/\[slides\][\s\S]*?\[endslides\]/g, "")
                        .substring(0, 100) + "..."}
                  <br />
                  <button
                    className="text-sm font-semibold hover:text-neutral-900 transition mt-2"
                    onClick={() => setDescriptionVisible(!descriptionVisible)}
                    style={{
                      color: LhumosSpaces[SPACE_INDEX].color1,
                    }}
                  >
                    {descriptionVisible ? "Show Less ↥" : "Show More ↧"}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="lg:col-span-3 md:col-span-12 sm:col-span-12">
            <SharedFiles collectionId={COLLECTION_ID} spaceId={SPACE_INDEX} />
            {files.length > 1 && (
              <div
                className="rounded-3xl px-4 pt-2 pb-4 mt-4 mb-4"
                style={{
                  backgroundImage: `linear-gradient(to bottom, ${LhumosSpaces[SPACE_INDEX].color2}, rgba(0, 0, 0, 0))`,
                }}
              >
                <h5 className="text-white -mb-4">Attached files</h5>
                {files.map((file, idx) => (
                  <div key={idx}>
                    {file.contentType === "application/pdf" && (
                      <PdfContainer spaceIndex={SPACE_INDEX}>
                        {file}
                      </PdfContainer>
                    )}
                    {file.contentType === "text/url" && (
                      <UrlContainer>{file}</UrlContainer>
                    )}
                    {file.contentType === "multi/files-zipped" && (
                      <ZipContainer>{file}</ZipContainer>
                    )}
                  </div>
                ))}
              </div>
            )}
            <div
              className="rounded-3xl p-4 h-auto"
              style={{
                backgroundImage: `linear-gradient(to bottom, ${LhumosSpaces[SPACE_INDEX].color2}, rgba(0, 0, 0, 0))`,
              }}
            >
              <h5 className="text-white -mt-4">Playlist</h5>
              <Slider {...playlistSettings}>
                {playlist.map((p, idx) => (
                  <Link
                    key={p.id}
                    to={`/Player/${SPACE_INDEX}/${idx}/${COLLECTION_ID}/${p.id}`}
                    onClick={() => setIsLoading(true)}
                    className="no-underline"
                  >
                    <div
                      className={`relative rounded-xl mb-2 h-34 transition delay-150 ease-in-out hover:-translate-y-1 hover:scale-95 ${
                        idx === Number(VIDEO_NUMBER)
                          ? "border-4 border-red-600 outline-none"
                          : ""
                      }`}
                      alt={p.name}
                      style={{
                        borderColor: LhumosSpaces[SPACE_INDEX].accent,
                      }}
                    >
                      <h6
                        className="text-white rounded-full w-8 h-8 right-2 bottom-2 flex justify-center items-center absolute"
                        style={{
                          backgroundColor: LhumosSpaces[SPACE_INDEX].accent,
                        }}
                      >
                        {idx + 1}
                      </h6>
                      <div
                        className="text-white text-sm rounded-lg h-32"
                        style={{
                          background: `linear-gradient(rgba(0, 0, 0, 8), rgba(0, 0, 0, 0)), url("https://clowder.eessi.science/fileThumbnail/${p.thumbnail}/blob")`,
                          backgroundSize: "cover",
                        }}
                      >
                        <div className="rounded-lg p-4">
                          <p>{p.name}</p>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Player;
