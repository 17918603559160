import React from "react";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Footer from "./components/Footer";
import LhumosSpaces from "./spaces.json"; //spaces whitelist database
import LoadingScreen from "./components/LoadingScreen";
import { getSpaces } from "./components/APImodules.js";
import Breadcrumbs from "./components/Breadcrumbs";
import atomPlanet from "./img/atom_planet.png";

//IMAGES
import atoms from "./img/atoms.png";

let SpaceList = [];

function Spaces() {
  //STATE
  const [isLoading, setIsLoading] = useState(true);
  const [spaces, setSpaces] = useState([]);

  //API CALLS
  useEffect(() => {
    async function fetchSpaces() {
      setIsLoading(true);
      const result = await getSpaces();
      //filters spaces out based on the spaces list
      SpaceList = [];
      for (let i = 0; i < LhumosSpaces.length; i++) {
        for (let j = 0; j < result.length; j++) {
          if (result[j].id === LhumosSpaces[i].id) {
            SpaceList.push(result[j]);
          }
        }
      }
      console.log("Filtered space details:");
      console.log(SpaceList);
      setSpaces(SpaceList);
      setIsLoading(false);
    }
    fetchSpaces();
  }, []);

  //HTML CODE
  if (isLoading) {
    return <LoadingScreen></LoadingScreen>;
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-[#4883b8] via-sky-900 to-black relative overflow-hidden">
      <img
        src={atomPlanet}
        alt="Atom Planet"
        className="absolute w-1/2 top-40 -right-40 opacity-25 rotating"
        style={{
          animation: "rotation 120s infinite linear",
          transformOrigin: "center center",
        }}
      />

      <style>
        {`
    @keyframes rotation {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(-360deg);
      }
    }
  `}
      </style>
      <div className="mx-8 my-1 max-w-screen-xl  mt-4 m-auto">
        <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3 relative z-10 mt-20 p-2">
          {spaces.map((space, idx) => (
            <Link
              to={"/spaces/" + idx}
              key={space.id}
              style={{ textDecoration: "none" }}
              className="transition ease-in-out hover:-translate-y-1 hover:scale-95"
            >
              <div
                className="h-80 bg-cover bg-center bg-no-repeat rounded-2xl"
                style={{
                  backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)), url(${LhumosSpaces[idx].bgurl})`,
                }}
              >
                <div className="flex items-center">
                  <img
                    className="h-24 w-24 rounded-2 m-4"
                    src={LhumosSpaces[idx].iconurl}
                    alt="logo"
                  ></img>
                  <h3 className="text-xl text-white ml-2">{space.name}</h3>
                </div>
                <div className="ml-4 mr-4 mt-2 backdrop-blur-xl p-2 rounded-xl">
                  <p className="text-sm text-white">
                    {space.description.substr(0, 300)}
                  </p>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default Spaces;
