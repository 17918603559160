import React, { useEffect, useState } from 'react';
import css from "./LoadingScreen.module.css";
import "animate.css";
import icon from "../img/logo_icon_transparent.png";
import { useParams } from "react-router-dom";
import LhumosSpaces from "../spaces.json"; // Adjust the import path as needed

function LoadingScreen() {
  const { spaceId } = useParams();
  const [gradientColors, setGradientColors] = useState({ color1: '#4883b8', color2: '#4883b8' }); // Default colors

  useEffect(() => {
    // Function to fetch space details and set gradient colors
    const fetchSpaceDetails = () => {
      const spaceDetails = LhumosSpaces[spaceId];
      if (spaceDetails) {
        setGradientColors({ color1: spaceDetails.color1, color2: spaceDetails.color2 });
      }
    };

    fetchSpaceDetails();
  }, [spaceId]);

  return (
    <div className={css.loadingDiv} style={{ backgroundImage: `linear-gradient(to bottom, ${gradientColors.color1}, ${gradientColors.color2})` }}>
      <div className="animate__animated animate__flip">
        <img src={icon} className={css.loadingLogo} alt="Loading"></img>
      </div>
    </div>
  );
}

export default LoadingScreen;
