import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { search, getLhumosStructure } from './APImodules';
import Backdrop from './Backdrop';
import cross from "../img/cross.png";
import LhumosSpaces from '../spaces.json';

const SearchResults = ({ query, onClose }) => {
  const [searchResults, setSearchResults] = useState({ results: [], count: 0, from: 0, totalSize: 0 });
  const [isLoading, setIsLoading] = useState(true);
  const [lhumosStructure, setLhumosStructure] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const searchData = await search(query, 'dataset');
        const structure = await getLhumosStructure();
        
        setSearchResults(searchData || { results: [], count: 0, from: 0, totalSize: 0 });
        setLhumosStructure(structure);
      } catch (error) {
        console.error("Error fetching data:", error);
        setSearchResults({ results: [], count: 0, from: 0, totalSize: 0 });
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [query]);

  const findDatasetInStructure = (datasetId, structure) => {
    if (!Array.isArray(structure)) {
      console.error("Invalid structure:", structure);
      return null;
    }
    for (const space of structure) {
      console.log("Searching in space:", space.name);
      const dataset = findDatasetInCollections(datasetId, space.children);
      if (dataset) {
        return {
          spaceId: space.id,
          collectionId: dataset.collectionId,
          datasetIndex: dataset.datasetIndex
        };
      }
    }
    console.log("Dataset not found in Lhumos structure");
    return null;
  };

  const findDatasetInCollections = (datasetId, collections) => {
    if (!Array.isArray(collections)) {
      console.log("Collections is not an array:", collections);
      return null;
    }
    for (const collection of collections) {
      console.log("Searching in collection:", collection.name);
      if (Array.isArray(collection.children)) {
        const datasetIndex = collection.children.findIndex(child => child.id === datasetId);
        if (datasetIndex !== -1) {
          console.log("Dataset found:", collection.children[datasetIndex]);
          return {
            collectionId: collection.id,
            datasetIndex: datasetIndex
          };
        }
        const datasetInChildCollections = findDatasetInCollections(datasetId, collection.children);
        if (datasetInChildCollections) {
          return datasetInChildCollections;
        }
      } else {
        console.log("Collection has no children:", collection.name);
      }
    }
    return null;
  };

  const getSpaceIndex = (spaceId) => {
    return LhumosSpaces.findIndex(space => space.id === spaceId);
  };

  const handleResultClick = (result) => {
    if (!lhumosStructure) {
      console.error("Lhumos structure not available");
      return;
    }

    console.log("Searching for dataset:", result.id);
    const datasetInfo = findDatasetInStructure(result.id, lhumosStructure);
    if (!datasetInfo) {
      console.error("Dataset not found in Lhumos structure");
      return;
    }

    const { spaceId, collectionId, datasetIndex } = datasetInfo;
    const spaceIndex = getSpaceIndex(spaceId);
    if (spaceIndex === -1) {
      console.error("Space not found in LhumosSpaces array");
      return;
    }

    const link = `/player/${spaceIndex}/${datasetIndex}/${collectionId}/${result.id}`;
    
    console.log("Navigating to link:", link);

    onClose();
    navigate(link);
  };

  const { results, count, from, totalSize } = searchResults;

  const pathname = location.pathname;
  const isSpacePage = pathname.startsWith("/spaces/") || pathname.startsWith("/player/");
  const SPACE_INDEX = isSpacePage ? pathname.split("/")[2] : null;

  const spaceColor1 = SPACE_INDEX ? LhumosSpaces[SPACE_INDEX]?.color1 || "" : "#4883b8";
  const spaceColor2 = SPACE_INDEX ? LhumosSpaces[SPACE_INDEX]?.color2 || "" : "#5190c6";
  const spaceColor3 = SPACE_INDEX ? LhumosSpaces[SPACE_INDEX]?.accent || "" : "#63a1ff";

  const containerStyle = SPACE_INDEX ? { backgroundColor: spaceColor1 } : {};

  return (
    <>
      <Backdrop onClick={onClose} />
      <div className="fixed inset-0 z-50 flex items-center justify-center overflow-hidden">
        <div className="w-2/3 h-5/6 bg-opacity-70 backdrop-blur-md rounded-lg shadow-2xl overflow-hidden" style={containerStyle}>
          <div className="p-6 overflow-y-auto h-full relative">
            <button
              className="absolute top-4 right-4 text-white text-2xl focus:outline-none"
              onClick={onClose}
            >
              <img src={cross} className="w-8" alt="close" />
            </button>
            <h2 className="text-2xl mb-4 text-white">Search Results</h2>
            {isLoading ? (
              <p className="text-white">Loading...</p>
            ) : (
              <>
                <p className="mb-4 text-white">
                  Showing {from + 1} to {from + count} of {totalSize} results
                </p>
                <ul className="space-y-4">
                  {results.map((result) => (
                    <li key={result.id} className="bg-white bg-opacity-80 p-4 rounded-lg shadow-md">
                      <div 
                        className="hover:underline cursor-pointer"
                        onClick={() => handleResultClick(result)}
                        style={{ color: spaceColor3 }}
                      >
                        <h3 className="text-lg font-semibold">{result.name}</h3>
                      </div>
                      <p className="text-gray-700 line-clamp-2 mt-2">{result.description}</p>
                      <p className="text-sm text-gray-500 mt-1">
                        Dataset ID: {result.id}
                      </p>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchResults;