import React from "react";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import css from "./Pages.module.css";
import scss from "./Spaces.module.css";
import LoadingScreen from "./components/LoadingScreen";
import ApiGet from "./components/APImodules.js";
let spaceID;

function DevExplorer() {
  //STATE
  const [isLoading, setIsLoading] = useState(true);
  const [spaces, setSpaces] = useState([]);
  const [display, setDisplay] = useState([]);

  //API CALLS

  useEffect(() => {
    setIsLoading(true);
    GetCollections();
    GetSpaces();
    setIsLoading(false);
  }, []);

  async function GetCollections() {
    const result = await ApiGet(
      `https://clowder.eessi.science/api/spaces/${spaceID}/collections`
    );
    var collection = "";
    collection += "COLLECTIONS inside: " + result.length + "\n" + "\n";
    for (var i = 0; i < result.length; i++) {
      collection +=
        i + ") " + result[i].id + "\n" + result[i].collectionname + "\n";
    }
    setDisplay(collection);
  }

  async function GetSpaces() {
    setSpaces( await ApiGet(
      `https://clowder.eessi.science/api/spaces?limit=64`
    ));
  }

  const handleChange = (event) => {
    spaceID = event.target.value;

    console.log("value is:", spaceID);
  };

  GetCollections();

  //HTML CODE
  if (isLoading) {
    return <LoadingScreen></LoadingScreen>;
  }

  return (
    <div className={css.body}>
      <div className={css.layout}>
        <div className={scss.scontainer}>
          <input
            type="text"
            name="idform"
            placeholder="SPACE ID"
            className={css.search}
            onChange={handleChange}
            value={spaceID}
          ></input>
          <button type="submit" onClick={() => GetCollections()}>
            search
          </button>
          <br></br>
          <textarea
            id="display"
            cols="30"
            rows="10"
            readOnly
            value={display}
          ></textarea>
          <textarea
            id="display"
            cols="30"
            rows="10"
            readOnly
            value={display}
          ></textarea>
        </div>
        <div className={scss.scontainer}>
          <div className="row">
            {spaces.map((space, idx) => (
              <div
                className="col-4"
                key={space.id}
                style={{ textDecoration: "none" }}
              >
                <div
                  className={scss.scard}
                  style={{
                    background: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0)), url(${require("./img/wp1.jpg")})`,
                  }}
                >
                  <div className="col-9">
                    <h3>{space.name}</h3>
                    <h6>{"created " + space.created}</h6>
                    <h6>{"id " + space.id}</h6>
                  </div>
                  <div className={css.spacing}></div>
                  <div className={css.cardtransparent}>
                    <h6>{space.description.substr(0, 400) + "..."}</h6>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DevExplorer;
