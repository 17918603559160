export const ExtractJsonFromDescription = (description) => {
  if (!description) return { json: null, remainingDescription: "" };

  const regex = /\[order\](.*?)\[endorder\]/s;
  const match = description.match(regex);
  if (match && match[1]) {
    try {
      const json = JSON.parse(match[1].trim());
      const remainingDescription = description.replace(match[0], "").trim();
      return { json, remainingDescription };
    } catch (error) {
      console.error("Error parsing JSON:", error);
    }
  }
  return { json: null, remainingDescription: description };
};
export default ExtractJsonFromDescription;
