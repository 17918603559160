import React from "react";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import css from "./Pages.module.css";
import scss from "./Spaces.module.css";
import CollectionContainer from "./components/CollectionContainer";
import LhumosSpaces from "./spaces.json"; //spaces whitelist database
import LoadingScreen from "./components/LoadingScreen";
import ApiGet from "./components/APImodules.js";

import accountimage from "./img/scientist.png";

let space;

function User() {
  //STATE

  //URL PARAMETERS

  //API CALLS

  //HTML CODE
  return (
    <div className={css.body}>
      <div className={css.layout}>
        <div className="row">
          <div className="col-3">
            <div
              className={css.cardwp}
              style={{
                background: `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5)), url(${accountimage})`,
              }}
            >
              <div className={css.spacing4}></div>
              <div className={css.spacing4}></div>
                <h1>Nome Cognome</h1>
            </div>
          </div>

          <div className="col-9">
            <div
              className={css.cardwp}
            >
              <div className={css.spacing4}></div>
              <div className={css.spacing4}></div>
            </div>
          </div>
        </div>

        <div className={css.spacing2}>
          <h1>Just watched - Liked videos</h1>
        </div>

        <div className={scss.scontainer}>
          <div className="row"></div>
        </div>
      </div>
    </div>
  );
}

export default User;
