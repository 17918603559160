import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import { useState } from "react";

import LhumosSpaces from "../spaces.json"; // spaces whitelist database

// LOGIN
import Login from "./Login";
import Backdrop from "./Backdrop";
import SearchResults from "./SearchResults";

// ICONS
import logo from "../img/logo.png";
import lens from "../img/zoom-lens.png";
import compass from "../img/compass.png";
import web from "../img/web.png";

function Navbar() {
  // LOGIN
  const [loginIsOpen, setLoginIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [showSearchResults, setShowSearchResults] = useState(false);

  const openLogin = () => setLoginIsOpen(true);
  const closeLogin = () => setLoginIsOpen(false);

  const handleSearch = () => {
    if (searchQuery.trim() !== "") {
      setShowSearchResults(true);
    }
  };

  const closeSearchResults = () => {
    setShowSearchResults(false);
  };

  const location = useLocation();
  const pathname = location.pathname;
  const isSpacePage = pathname.startsWith("/spaces/") || pathname.startsWith("/player/");
  const SPACE_INDEX = isSpacePage ? pathname.split("/")[2] : null;

  const spaceColor1 = SPACE_INDEX ? LhumosSpaces[SPACE_INDEX]?.color1 || "" : "#4883b8";
  const spaceColor2 = SPACE_INDEX ? LhumosSpaces[SPACE_INDEX]?.color2 || "" : "#5190c6";
  const spaceColor3 = SPACE_INDEX ? LhumosSpaces[SPACE_INDEX]?.accent || "" : "#63a1ff";

  const navbarStyle = SPACE_INDEX ? { backgroundColor: spaceColor1 } : {};

  return (
    <header>
      {loginIsOpen && (
        <>
          <Login onClick={closeLogin} />
          <Backdrop onClick={closeLogin} />
        </>
      )}
      <nav className={`sticky top-0 flex ${SPACE_INDEX ? 'justify-start' : 'justify-center'}`} style={navbarStyle}>
        <div className="flex justify-between items-center w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center mt-2">
            <Link to="/" className="text-white mr-6">
              <img src={logo} className="w-28" alt="logo" />
            </Link>
            <Link to="/spaces" className="flex items-center text-white no-underline">
              <img src={web} alt="spaces" className="w-6 h-6 mr-2" />
              Spaces
            </Link>
            <Link to="/map" className="flex items-center text-white no-underline ml-4">
              <img src={compass} alt="Map" className="w-6 h-6 mr-2" />
              Map
            </Link>
          </div>
          
          <div className="relative mt-2">
            <input
              type="text"
              className="pl-8 pr-4 py-1 rounded-xl focus:outline-non "
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              style={{
                backgroundColor: spaceColor2,
                color: "white"
              }}
            />
            <button
              className="absolute right-0 top-0 bottom-0 px-4 rounded-r-xl hover:opacity-75 focus:outline-none"
              onClick={handleSearch}
              style={{
                backgroundColor: spaceColor2,
                color: spaceColor2
              }}
            >
              <img src={lens} className="w-8" alt="search" />
            </button>
          </div>
        </div>
      </nav>
      {showSearchResults && (
        <SearchResults query={searchQuery} onClose={closeSearchResults} />
      )}
    </header>
  );
}

export default Navbar;
