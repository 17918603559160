import React, { useEffect, useState } from "react";
import Footer from "./components/Footer";
import { getLhumosStructure } from "./components/APImodules.js";
import Tree from "react-d3-tree";
import LoadingScreen from "./components/LoadingScreen";
import { useParams } from "react-router-dom";
import LhumosSpaces from "./spaces.json";
import { RefreshCw } from "lucide-react";

// Utility functions for storage
const safeSetItem = (key, value) => {
  try {
    const serializedValue = JSON.stringify(value);
    localStorage.setItem(key, serializedValue);
  } catch (e) {
    if (e.name === "QuotaExceededError") {
      console.warn("localStorage quota exceeded. Clearing and retrying.");
      localStorage.clear();
      try {
        localStorage.setItem(key, JSON.stringify(value));
      } catch (retryError) {
        console.error(
          "Failed to save to localStorage even after clearing:",
          retryError
        );
      }
    } else {
      console.error("Failed to save to localStorage:", e);
    }
  }
};

const safeGetItem = (key) => {
  try {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  } catch (e) {
    console.warn("Failed to get from localStorage:", e);
    return null;
  }
};

function LhumosMap() {
  const [isLoading, setIsLoading] = useState(true);
  const [treeData, setTreeData] = useState(null);
  const { spaceId } = useParams();
  const [gradientColors, setGradientColors] = useState({
    color1: "#4883b8",
    color2: "#4883b8",
  });
  const [stats, setStats] = useState({
    spaceCount: 0,
    collectionCount: 0,
    datasetCount: 0,
  });

  useEffect(() => {
    loadData();

    // Set gradient colors based on space
    const spaceDetails = LhumosSpaces.find((s) => s.id === spaceId);
    if (spaceDetails) {
      setGradientColors({
        color1: spaceDetails.color1,
        color2: spaceDetails.color2,
      });
    }
  }, [spaceId]);

  const loadData = () => {
    const cachedData = safeGetItem("lhumosStructure");
    if (cachedData) {
      setTreeData(cachedData.treeData);
      setStats(cachedData.stats);
      setIsLoading(false);
    } else {
      fetchGraphData();
    }
  };

  async function fetchGraphData() {
    setIsLoading(true);
    const stats = { spaceCount: 0, collectionCount: 0, datasetCount: 0 };

    try {
      const lhumosStructure = await getLhumosStructure();

      const root = {
        name: "Lhumos",
        children: lhumosStructure.map(space => ({
          name: space.name,
          children: processChildren(space.children, stats)
        }))
      };

      stats.spaceCount = lhumosStructure.length;

      setTreeData(root);
      setStats(stats);
      safeSetItem("lhumosStructure", { treeData: root, stats });
    } catch (error) {
      console.error("Error fetching Lhumos structure:", error);
      setTreeData({ name: "Error Loading Lhumos Structure", type: "error" });
    }

    setIsLoading(false);
  }

  function processChildren(children, stats) {
    return children.map(child => {
      if (child.group === 'space') {
        const spaceDetails = LhumosSpaces.find(space => space.id === child.id);
        const spaceName = spaceDetails ? spaceDetails.name : child.name;
        return {
          name: spaceName,
          children: child.children ? processChildren(child.children, stats) : [],
          type: 'space',
        };
      } else if (child.group === 'collection' || child.group === 'unauthorized-collection') {
        stats.collectionCount++;
        const collectionName = child.name || 'Unnamed Collection';
        return {
          name: collectionName,
          description: child.description,
          children: processChildren(child.children, stats),
          type: child.group === 'unauthorized-collection' ? 'unauthorized' : 'collection',
        };
      } else if (child.group === 'dataset') {
        stats.datasetCount++;
        return { name: child.name, type: "dataset" };
      } else if (child.group === 'unauthorized-space') {
        return { name: 'Unauthorized Space', type: "unauthorized" };
      }
      return child;
    });
  }

  const handleRefresh = () => {
    localStorage.removeItem("lhumosStructure");
    fetchGraphData();
  };

  const renderCustomNode = ({ nodeDatum, toggleNode }) => (
    <g>
      <circle
        r={20}
        fill={getNodeColor(nodeDatum)}
        stroke="white"
        strokeWidth="4"
        onClick={toggleNode}
      />
      <text
        fill="white"
        strokeWidth="1"
        stroke="white"
        fontSize={20}
        x={30}
        dy=".31em"
        style={{ pointerEvents: "none" }}
      >
        {nodeDatum.name}
      </text>
      {nodeDatum.description && (
        <text
          fill="white"
          strokeWidth="1"
          stroke="white"
          fontSize={15}
          x={30}
          dy="2em"
          style={{ pointerEvents: "none" }}
        >
          {nodeDatum.description.substring(0, 50)}...
        </text>
      )}
    </g>
  );

  const getNodeColor = (nodeDatum) => {
    if (nodeDatum.name === "Lhumos") return "#d8b4fe"; // purple-300
    if (nodeDatum.type === "unauthorized") return "#fdba74"; // orange-300
    if (nodeDatum.children && nodeDatum.children.length > 0)
      return "#93c5fd"; // blue-300 (Space or Collection with children)
    if (nodeDatum.type === "dataset") return "#86efac"; // green-300
    if (nodeDatum.type === "error") return "#ef4444"; // red-500
    return "#fde047"; // yellow-300 (Default)
  };

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <div
      className="min-h-screen relative overflow-hidden"
      style={{
        backgroundImage: `linear-gradient(to bottom, ${gradientColors.color1}, ${gradientColors.color2})`,
      }}
    >
      <div className="mx-8 my-1 max-w-screen-xl mt-4 m-auto">
        <div className="relative z-10 mt-20 p-2" style={{ height: "80vh" }}>
          <div className="text-white mb-4 flex justify-between items-center">
            <div className="text-xl font-bold">Lhumos Structure</div>
            <div className="flex space-x-4 items-center">
              <span className="bg-blue-300 text-blue-800 px-2 py-1 rounded">
                {stats.spaceCount} Spaces
              </span>
              <span className="bg-green-300 text-green-800 px-2 py-1 rounded">
                {stats.collectionCount} Collections
              </span>
              <span className="bg-yellow-300 text-yellow-800 px-2 py-1 rounded">
                {stats.datasetCount} Datasets
              </span>
              <button
                onClick={handleRefresh}
                className="bg-white text-blue-500 p-1 rounded-full hover:bg-blue-100 transition-colors duration-200"
                title="Refresh"
              >
                <RefreshCw size={20} />
              </button>
            </div>
          </div>
          <div style={{ width: "100%", height: "calc(100% - 40px)" }}>
            <Tree
              data={treeData}
              orientation="horizontal"
              renderCustomNodeElement={renderCustomNode}
              pathFunc="curve"
              separation={{ siblings: 1, nonSiblings: 2 }}
              translate={{ x: 100, y: 200 }}
              nodeSize={{ x: 800, y: 100 }}
              zoomable={true}
              collapsible={true}
              initialDepth={2}
              pathClassFunc={() => "stroke-white stroke-1"}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default LhumosMap;