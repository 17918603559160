import React, { useRef, useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import Slider from "react-slick";
import ApiGet from "./APImodules.js";
import Backdrop from "./Backdrop";
import LoadingCircle from "./LoadingCircle.js";
import LhumosSpaces from "../spaces.json";

//ICONS
import pdf from "../img/filetypes/pdf.png";
import download from "../img/download-arrow.png";
import viewImage from "../img/view.png";
import viewPdf from "../img/view.png";
import closeIcon from "../img/cross.png";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function PdfContainer(props) {
  const FILE_ID = props.children.id;
  const spaceIndex = props.spaceIndex;
  const theme = LhumosSpaces[spaceIndex];

  //STATE
  const [isLoading, setIsLoading] = useState(true);
  const [isPdfLoading, setIsPdfLoading] = useState(true);
  const [isImageView, setIsImageView] = useState(true);
  const [pdfMetadata, setPdfMetadata] = useState(null);
  const [pdfViewerOpen, setPdfViewerOpen] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [previewArray, setPreviewArray] = useState([]);

  function openPdfViewer() {
    setPdfViewerOpen(true);
  }
  function closePdfViewer() {
    setPdfViewerOpen(false);
  }
  function nextPage() {
    setPageNumber((prevPage) =>
      prevPage < pdfMetadata[0].content.num_pages ? prevPage + 1 : 1
    );
    changeSelectedSlidePage(pageNumber - 1);
  }
  function previousPage() {
    setPageNumber((prevPage) =>
      prevPage === 1 ? pdfMetadata[0].content.num_pages : prevPage - 1
    );
    changeSelectedSlidePage(pageNumber - 1);
  }

  function changeView() {
    setIsImageView((prevView) => !prevView);
  }

  //API CALLS
  useEffect(() => {
    setIsLoading(true);
    GetDatasets();
  }, []);

  async function GetDatasets() {
    const result = await ApiGet(
      `https://clowder.eessi.science/api/files/${FILE_ID}/metadata.jsonld`
    );
    setPdfMetadata(result);

    // Map through the preview images and set the correct URLs in previewArray
    const newArray = result[0].content.preview_images.map(
      (image) => `https://clowder.eessi.science/api/previews/${image}`
    );
    setPreviewArray(newArray);

    setIsLoading(false);
  }

  //slider carousel variables
  const sliderRef = useRef(null);
  const [slideIndex, setSlideIndex] = useState(0);
  const sliderSettings = {
    infinite: false,
    vertical: true,
    speed: 300,
    slidesToShow: 4,
    centerMode: true,
    beforeChange: (current, next) => setSlideIndex(next),
  };

  if (isLoading) {
    return <LoadingCircle />;
  }

  if (pdfViewerOpen) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "visible";
  }

  function jumpToPage(index) {
    setPageNumber(index + 1);
    setSlideIndex(index);
  }

  function changeSelectedSlidePage(index) {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(index);
    }
  }

  return (
    <div
      className="text-white py-2 px-4 rounded mt-4 cursor-pointer"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.2)), url("https://clowder.eessi.science/api/previews/${pdfMetadata[0].content.preview_images[0]}")`,
        backgroundColor: theme.color2,
      }}
    >
      {pdfViewerOpen && (
        <>
          <div className="fixed inset-0 z-40 backdrop-filter backdrop-blur-md"></div>
          <div className="fixed inset-0 z-50 flex items-center justify-center">
            <div
              className="rounded-lg shadow-lg p-4 flex relative"
              style={{
                backgroundImage: `linear-gradient(to bottom, ${theme.color2}, rgba(0, 0, 0, 0.8))`,
              }}
            >
              <img
                src={closeIcon}
                alt="Close"
                className="absolute top-2 right-2 h-6 w-6 cursor-pointer"
                onClick={closePdfViewer}
              />
              <div className="mr-8 w-32">
                <Slider {...sliderSettings} ref={sliderRef}>
                  {previewArray.map((url, idx) => (
                    <div
                      key={idx}
                      onClick={() => jumpToPage(idx)}
                      className={`w-8 rounded-xl cursor-pointer transition duration-300 ease-in-out hover:scale-105 ${
                        idx === slideIndex
                          ? `border-4 border-[${theme.accent}] outline-none`
                          : ""
                      }`}
                    >
                      <div
                        className="text-white rounded-lg w-10 h-10 flex justify-center items-center font-semibold p-3 absolute"
                        style={{
                          backgroundColor: theme.accent,
                        }}
                      >
                        {idx + 1}
                      </div>
                      <img
                        src={url}
                        alt={`Page ${idx + 1}`}
                        className="rounded-xl"
                      />
                    </div>
                  ))}
                </Slider>
              </div>

              <div className="text-center">
                <div
                  onClick={changeView}
                  className="p-4 cursor-pointer flex items-center justify-center mb-4"
                >
                  <img
                    src={isImageView ? viewPdf : viewImage}
                    alt="Change View"
                    className="h-8 w-8 mr-2"
                  />
                  <span className="text-white">
                    {isImageView ? "View PDF" : "View Image"}
                  </span>
                </div>
                {isImageView ? (
                  <div
                    className="w-[40rem] h-[32rem] bg-contain bg-no-repeat"
                    style={{
                      backgroundImage: `url("https://clowder.eessi.science/api/previews/${pdfMetadata[0].content.preview_images[pageNumber - 1]}")`,
                    }}
                  ></div>
                ) : (
                  <div className="w-[40rem] h-[32rem]">
                    {isPdfLoading && <LoadingCircle />}
                    <Document
                      file={`https://clowder.eessi.science/api/files/${props.children.id}/blob/`}
                      onLoadSuccess={() => setIsPdfLoading(false)}
                      loading={<LoadingCircle />}
                    >
                      <Page pageNumber={pageNumber} width={640} />
                    </Document>
                  </div>
                )}
                <div className="mt-4 flex items-center justify-center">
                  <button
                    className="text-white font-bold py-2 px-4 rounded-l"
                    onClick={previousPage}
                    style={{
                      backgroundColor: theme.accent,
                    }}
                  >
                    <p className="text-4xl transition duration-300 ease-in-out hover:scale-105">
                      ←
                    </p>
                  </button>
                  <span className="mx-4 text-white">
                    {pageNumber}/{pdfMetadata[0].content.num_pages}
                  </span>
                  <button
                    className="text-white font-bold py-2 px-4 rounded-r"
                    onClick={nextPage}
                    style={{
                      backgroundColor: theme.accent,
                    }}
                  >
                    <p className="text-4xl transition duration-300 ease-in-out hover:scale-105">
                      →
                    </p>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {pdfViewerOpen && <Backdrop onClick={closePdfViewer} />}

      <div onClick={openPdfViewer}>
        <div className="pr-2 relative">
          <img src={pdf} className="absolute h-10 w-10 -left-10 top-2" alt="PDF Icon"></img>
        </div>
        <div className="flex">
          <div className="p-2 flex-initial w-40 break-words">
            <strong>{props.children.filename}</strong>
            <br />
            pages: {pdfMetadata[0].content.num_pages}
            <br />
            {String(pdfMetadata[0].content.pdf_size_mb).substring(0, 5) + " MB"}
            <br />
          </div>
          <div className="flex-grow"></div>
          <div className="py-2 pr-2">
            <a
              href={`https://clowder.eessi.science/api/files/${props.children.id}/`}
            >
              <img src={download} className="h-8 mt-4" alt="Download Icon"></img>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PdfContainer;
