import { useRef, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import LoadingScreen from "./components/LoadingScreen.js";
import "./Player.css";
import ApiGet from "./components/APImodules.js";
import ExtractJsonFromDescription from "./components/ExtractJsonFromDescription.js";



function PlaylistEditor() {
  // States and URL parameters
  const [isLoading, setIsLoading] = useState(true);
  const [videosInPlaylist, setVideosInPlaylist] = useState([]);
  const [apiKey, setApiKey] = useState("");
  const [collection, setCollection] = useState("");
  const [initialDescription, setInitialDescription] = useState("");
  const [injectedJson, setInjectedJson] = useState("");
  const [initialTitle, setInitialTitle] = useState("");
  const [editedOrder, setEditedOrder] = useState("");
  const {
    spaceId: SPACE_INDEX,
    videoNumber: VIDEO_NUMBER,
    collectionId: COLLECTION_ID,
  } = useParams();
  const isInitialized = useRef(false);

  useEffect(() => {
    if (!isInitialized.current) {
      GetAllData();
    }
  }, [COLLECTION_ID]);

  // API Calls
  const GetAllData = async () => {
    setIsLoading(true);
    const collectionData = await ApiGet(
      `https://clowder.eessi.science/api/collections/${COLLECTION_ID}`
    );

    // Use the imported function to extract JSON
    const { json: extractedJSON, remainingDescription } = ExtractJsonFromDescription(collectionData.description);
    setInitialDescription(remainingDescription);
    if (extractedJSON) {
      setVideosInPlaylist(extractedJSON);
      setInjectedJson(JSON.stringify(extractedJSON)); // Update injectedJson state
    } else {
      const videos = await ApiGet(
        `https://clowder.eessi.science/api/collections/${COLLECTION_ID}/datasets/?key=test`
      );
      setVideosInPlaylist(videos);
    }
    setCollection(collectionData);
    setInitialTitle(collectionData.name);
    setIsLoading(false);
    isInitialized.current = true;
  };

  // Processing the data
  useEffect(() => {
    if (!isLoading && collection.description) {
      const extractedJSON = ExtractJsonFromDescription(collection.description);
      if (extractedJSON !== null) {
        setEditedOrder(extractedJSON);
      }
    }
  }, [isLoading, collection.description]);

  const updateCollectionDescription = async () => {
    //UPDATE DESCRIPTION CALL
    const description =
      initialDescription +
      "[order]" +
      JSON.stringify(videosInPlaylist) +
      "[endorder]";
    const url = `https://clowder.eessi.science/api/collections/${COLLECTION_ID}/description?key=${apiKey}`;
    console.log(description);

    try {
      const response = await fetch(url, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ description }),
      });

      if (!response.ok) {
        toast.error("Failed to update");
        throw new Error("Failed to update collection description");
      }

      toast.success("Updated successfully");
      console.log("Collection description updated successfully");
      setIsLoading(true);
      GetAllData();
    } catch (error) {
      console.error(error);
    }
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(videosInPlaylist);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setVideosInPlaylist(items);
  };

  const handleChangeDescription = (event) => {
    setInitialDescription(event.target.value);
  };
  const handleChangeTitle = (event) => {
    setInitialTitle(event.target.value);
  };

  if (isLoading) return <LoadingScreen />;

  return (
    <div className="m-auto px-4 min-h-screen bg-gradient-to-b from-[#4883b8] via-sky-900 to-black overflow-hidden">
      <ToastContainer />
      <div className="grid grid-cols-12 max-w-screen-xl m-auto">
        <div className="bg-sky-700 border-none rounded-3xl p-4 col-span-3 m-4">
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {videosInPlaylist.map((p, idx) => (
                    <Draggable key={p.id} draggableId={p.id} index={idx}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="rounded-xl mb-2 transition delay-150 ease-in-out hover:-translate-y-1 hover:scale-95 relative"
                        >
                          <div className="text-white rounded-full w-10 h-10 flex justify-center items-center font-semibold p-3 absolute -left-8 top-10 bg-blue-400">
                            {idx + 1}
                          </div>
                          <div
                            className="text-white text-sm rounded-lg h-32"
                            style={{
                              background: `linear-gradient(rgba(0, 0, 0, 5), rgba(0, 0, 0, 0)), url("https://clowder.eessi.science/fileThumbnail/${p.thumbnail}/blob")`,
                              backgroundSize: "cover",
                            }}
                          >
                            <div className="rounded-lg p-4">
                              <p>{p.name}</p>
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
        <div className="col-span-9">
          <div className="h-auto bg-sky-700 text-white border-none rounded-3xl p-4 m-4">
            <h2>Playlist settings Dashboard</h2>
            <input
              type="password"
              className="rounded py-2 px-4 focus:outline-none focus:ring-2 focus:ring-blue-500 w-96 mr-2 mb-4 bg-sky-800"
              placeholder="Enter your personal clowder API key"
              value={apiKey}
              onChange={(e) => setApiKey(e.target.value)}
            />
            <button
              className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              onClick={() => {
                updateCollectionDescription();
              }}
            >
              Save & Apply
            </button>
            <h4>API IDs</h4>
            <div className="flex">
              <p className="bg-blue-400 text-white py-2 px-4 rounded mr-2">
                <strong>Space N:</strong> {SPACE_INDEX}
              </p>
              <p className="bg-blue-400 text-white py-2 px-4 rounded">
                <strong>Collection id:</strong> {COLLECTION_ID}
              </p>
            </div>
            <strong>Collection title:</strong>
            <textarea
              type="text"
              className="rounded py-2 px-4 focus:outline-none focus:ring-2 focus:ring-blue-500 w-full mt-2 bg-sky-800"
              rows="3"
              placeholder="collection title"
              value={initialTitle}
              onChange={handleChangeTitle}
            />
            <strong>Video description:</strong>
            <textarea
              type="text"
              className="rounded py-2 px-4 focus:outline-none focus:ring-2 focus:ring-blue-500 w-full mt-2 bg-sky-800"
              rows="3"
              placeholder="collection  description"
              value={initialDescription}
              onChange={handleChangeDescription}
            />
            <strong>Injected Json</strong>
            <textarea
              type="text"
              className="rounded py-2 px-4 focus:outline-none focus:ring-2 focus:ring-blue-500 w-full mt-2 bg-sky-800"
              rows="3"
              placeholder="injectedJson"
              value={injectedJson}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlaylistEditor;
